import React, { useEffect, useMemo, useRef } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { Rate } from 'antd';
import { imgUrl } from '@/shared/utils';

const CustomersTalkUsContent = () => {
  const { t } = useTranslation('common');
  const containerRef = useRef(null);
  const router = useRouter();
  const { locale } = router;

  const dataList = useMemo(() => [
    {
      name: 'Sophia***',
      desc: t(
        "As a mom, I love DolphinRadar. It gives me all the info I need to ensure my kids are safe on Instagram. The detailed reports are invaluable. It's worth every penny.",
      ),
      rate: 5,
    },
    {
      name: 'Ethan***',
      desc: t(
        `I_used_DolphinRadar_due_to_trust_issues_and_found_out_my_partner_was_just_following_meme_accounts`,
      ),
      rate: 5,
    },
    {
      name: 'Olivia***',
      desc: t(
        "Love it! DolphinRadar gives me detailed insights into my favorite celebs' Instagram activities. It's like having a backstage pass! Highly recommend it.",
      ),
      rate: 5,
    },
    {
      name: 'Michael***',
      desc: t(
        'Love the insights DolphinRadar provides, but the interface is a bit clunky. Luckily, support guided me through it smoothly.',
      ),
      rate: 4,
    },
    {
      name: 'Johnson***',
      desc: t(
        'DolphinRadar is very detailed—maybe too much for casual users like me. Works well, though.',
      ),
      rate: 4.5,
    },
    {
      name: 'Davis***',
      desc: t(
        "As a digital marketer, DolphinRadar helps me track competitors' Instagram activities. It's a secret weapon for staying ahead!",
      ),
      rate: 4.5,
    },
  ], [])

  // const esDataList = [
  //   {
  //     name: 'Sofia Martinez',
  //     desc: `Nunca quise ser ese tipo que duda de su pareja, pero había señales que no podía ignorar. Con un corazón pesado, decidí usar DolphinRadar para entender mejor lo que estaba sucediendo en el mundo digital de mi novia. Lo que encontré confirmó mis peores temores. Había un patrón claro de interacción con alguien que yo apenas conocía, y no era simplemente amistoso. Ver esas interacciones, esos 'me gusta' en fotos íntimas y conversaciones ocultas, fue devastador. DolphinRadar me ayudó a enfrentar la realidad de que estábamos en caminos diferentes, y aunque duele, estoy agradecido por haberlo descubierto antes de que fuera demasiado tarde.`,
  //   },
  //   {
  //     name: 'Valentina Rodriguez',
  //     desc: `Como padre, la seguridad de mis hijos en internet me quita el sueño. Pero desde que descubrí DolphinRadar, puedo respirar tranquilo. Rastrear su actividad en Instagram de forma discreta me ha dado la certeza de que están lejos de cualquier contenido dañino. Es más, siento que puedo protegerlos sin invadir su espacio personal. ¡Una verdadera bendición!`,
  //   },
  //   {
  //     name: 'Martina López',
  //     desc: `¡DolphinRadar al rescate! Estaba totalmente perdido buscando el regalo ideal, pero un rápido vistazo a los 'me gusta' en Instagram de mi amigo me dio todas las pistas. Encontré esos materiales de arte que tanto deseaba, y ver su cara de sorpresa al recibirlos... ¡no tiene precio! Gracias, DolphinRadar, por hacerme quedar como el mejor amigo del mundo.`,
  //   },
  //   {
  //     name: 'Lucía Fernández',
  //     desc: `Soy súper fan de varias celebridades y siempre soñé con tener algún tipo de conexión con ellas. DolphinRadar me ha acercado a ese sueño. Ahora sé cuándo están más activos en Instagram y qué les interesa. Mi última publicación recibió un like de mi ídolo, ¡casi me desmayo! Se siente increíblemente personal, como si de alguna manera, estuviera más cerca de su mundo.`,
  //   },
  //   {
  //     name: 'Mateo García',
  //     desc: `Siempre he tenido mis dudas y, honestamente, no quería ser esa novia que revisa el teléfono de su pareja. Descubrir DolphinRadar fue un alivio total. Ahora puedo ver las interacciones de mi novio en Instagram de manera discreta, lo que me ha tranquilizado muchísimo. Ver que le gusta fotos de cachorritos y paisajes, y no solo de modelos en bikini, me ha hecho confiar más en él.`,
  //   },
  //   {
  //     name: 'Santiago Pérez',
  //     desc: `En el mundo de los negocios, la información es oro y DolphinRadar es mi mina. Analizando las actividades en Instagram de mis competidores, he podido adelantarme con estrategias que realmente impactan. Viendo sus tiempos de actividad y las interacciones recientes, ajusté mis publicaciones y ¡boom! Mi alcance y engagement se dispararon. Es como tener un espía en el campamento enemigo, pero todo legal y ético. ¡Imprescindible!`,
  //   },
  // ];

  // const ptDataList = [
  //   {
  //     name: 'Juan Rodríguez',
  //     desc: `O dolphin radar é sensacional! Eu posso ver o que a minha ex está fazendo no Instagram, sem ela saber que eu ainda estou de olho nela.  Eu posso ver se ela está feliz, se ela está namorando alguém, ou se ela ainda sente a minha falta. Eu posso matar a minha curiosidade, e até tentar reconquistá-la. O dolphin radar me dá uma segunda chance de amor.`,
  //   },
  //   {
  //     name: 'Carmen García',
  //     desc: `Como detetive particular, o Dolphin Radar tem sido uma ferramenta essencial no meu trabalho. Consigo acompanhar as atividades dos meus clientes no Instagram de maneira discreta e eficaz. Isso me ajuda a coletar informações importantes para os meus casos. É incrivelmente fácil de usar e muito confiável.`,
  //   },
  //   {
  //     name: 'Leonardo da Silva',
  //     desc: `Eu sou um fotógrafo profissional, e o dolphin radar é o meu assistente ideal. Eu posso monitorar as contas do Instagram dos meus clientes, e ver o que eles gostam, o que eles querem, e o que eles esperam.  Eu posso adaptar o meu trabalho às suas preferências, e oferecer um serviço personalizado e de qualidade.  O dolphin radar me ajuda a melhorar o meu negócio.`,
  //   },
  //   {
  //     name: 'Lucía Pérez',
  //     desc: `Como mãe de dois adolescentes, o Dolphin Radar foi uma descoberta incrível! Agora posso acompanhar facilmente o que meus filhos estão fazendo no Instagram sem que eles saibam. É tão simples de usar, só preciso digitar o nome de usuário deles. Me sinto muito mais segura sabendo que posso monitorar suas atividades online. Recomendo para todos os pais preocupados!`,
  //   },
  //   {
  //     name: 'Pablo González',
  //     desc: `Como jornalista, encontrar uma ferramenta como o Dolphin Radar foi uma virada de jogo. Posso seguir contas públicas de políticos e celebridades sem deixar rastros. Isso me ajuda a estar sempre um passo à frente nas notícias. Super intuitivo e eficiente.`,
  //   },
  //   {
  //     name: 'Maya Santos',
  //     desc: `Uso o Dolphin Radar para acompanhar a vida online do meu namorado. Sei que parece estranho, mas é tão fácil de usar e me dá uma paz de espírito incrível. Basta digitar o nome de usuário e pronto. Acho que todos que estão em um relacionamento deveriam usar isso, só para ter certeza.`,
  //   },
  // ];

  // const jaDataList = [
  //   {
  //     name: 'Yuki Nakamura',
  //     desc: `最近、彼氏が遠距離で何してるか心配だったけど、DolphinRadarのおかげで安心できるようになった！彼のインスタの動きが全部わかるから、疑いも減ったし、もっと信頼できるようになったよ。`,
  //   },
  //   {
  //     name: 'Haruto Tanaka',
  //     desc: `仕事で顧客の動きをチェックするのにDolphinRadarを使ってる。これで顧客が何に興味を持っているかすぐに分かるから、商談がスムーズに進むようになった。`,
  //   },
  //   {
  //     name: 'Aiko Kobayashi',
  //     desc: `大好きなアイドルの活動を知るために使ってるんだけど、DolphinRadarがあれば彼らの最新情報を逃さずキャッチできる！これでファン活動がもっと楽しくなった！`,
  //   },
  //   {
  //     name: 'Sakura Yamamoto',
  //     desc: `私の競争相手がどんなマーケティングをしているか知りたくて使い始めたんだけど、DolphinRadar、本当に便利！彼らのフォロワー動向をチェックして、自分の戦略を調整するのに役立ってる。`,
  //   },
  //   {
  //     name: 'Riku Suzuki',
  //     desc: `彼女がインスタで何をしてるのか知りたくてDolphinRadarを試してみたけど、本当に役立つツールだね。これで彼女の興味や活動をもっと理解できるようになったし、会話のネタも増えたよ。`,
  //   },
  // ];

  useEffect(() => {
    const container = containerRef.current;

    const scrollStep = 1;
    const scrollInterval = 30;

    const scroll = () => {
      container.scrollLeft += scrollStep;
      if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
        container.scrollLeft = 0;
      }
    };

    const intervalId = setInterval(scroll, scrollInterval);

    // 清理定时器
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // const getDataList = useMemo(() => {
  //   switch (locale) {
  //     case 'es':
  //       return esDataList;
  //     case 'pt':
  //       return ptDataList;
  //     case 'ja':
  //       return jaDataList;
  //     default:
  //       return dataList;
  //   }
  // }, [locale]);

  // const getContent = useMemo(() => {
  //   return (

  //   );
  // }, [dataList]);

  return (
    <>
      <div className={styles.content}>
        <h2>{t('OurCustomersTalkAboutUs')}</h2>
        <p className={styles.titleDesc}>
          {t(
            "To ensure the protection of our users' privacy, we've chosen to redact certain personal details from their reviews.",
          )}
        </p>
        <div className={styles.container} ref={containerRef}>
          {dataList.map((item, index) => {
            return (
              <div className={styles.cardContainer} key={index + item.name}>
                <div className={styles.userContainer}>
                  <Image
                    src={imgUrl(`https://res-gpts.pumpsoul.com/img/${item.name}.png`, 'small')}
                    alt={item.name}
                    className={styles.icon}
                    width={46}
                    height={46}
                  />
                  <span className={styles.userName}>{item.name}</span>
                </div>
                <Rate className={styles.star} allowHalf disabled defaultValue={item.rate} />
                <div className={styles.desc}>{item.desc}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CustomersTalkUsContent;
